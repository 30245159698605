import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { StyledGridRow } from "../../commons/Grid";
import { StyledButton } from "../globalstyle";
import { DataContext } from "../../contexts/DataContext";
import Countdown from "../Countdown/Countdown";

import {
  StyledForm,
  StyledFormBlock,
  StyledFormLabel,
  StyledFormInput,
} from "../../commons/Form";

import { 
  StyledCountdownNewsletter,
  StyledCountdownNewsletterCards,
  StyledCountdownNewsletterCard,
  StyledCountdownNewsletterTitle,
  StyledCountdownNewsletterButton,
} from "./style";

const CountdownNewsletter = ({ countdownDate }) => {
  return (
    <StyledCountdownNewsletter>
      <StyledGridRow noMargin>
        <StyledCountdownNewsletterCards>
          <CountdownNewsletterCard countdownDate={countdownDate} />
        </StyledCountdownNewsletterCards>
      </StyledGridRow>
    </StyledCountdownNewsletter>
  );
};
const CountdownNewsletterCard = ({ countdownDate }) => {
  return (
    <StyledCountdownNewsletterCard>
      {countdownDate && (<Countdown date={countdownDate} completedComponent={<CountdownNewsletterCompleted/>}/>)}
      <StyledCountdownNewsletterTitle>Iscriviti per saperne di più</StyledCountdownNewsletterTitle>
      <CountdownNewsletterForm/>
    </StyledCountdownNewsletterCard>
  );
};
const CountdownNewsletterCompleted = () => {
  return (
    <>
      <StyledCountdownNewsletterTitle>
        Ti aspettiamo al nostro negozio in Via San Michele del Carso, 18 a Milano - 10.30 | 19.30
      </StyledCountdownNewsletterTitle>
      <StyledCountdownNewsletterTitle>
        Scegli le api per Natale
      </StyledCountdownNewsletterTitle>
    </>
  );
};
const CountdownNewsletterForm = () => {
  const { labels } = useContext(DataContext);
  const [formData, setFormData] = useState({});

  return (
    <StyledForm
      Validate
      onSubmit={(event) => {
        event.preventDefault();
        toast.configure();
        const form_data = new FormData(event.currentTarget);

        setFormData({
          ...formData,
          email: null, 
        });
        fetch("https://api.ipify.org/")
          .then(response => response.text())
          .then(response =>{
            form_data.append("g-recaptcha-response", "token");
            form_data.append("lang", "IT");
            form_data.append("list_id", 82);
          
            fetch("https://3bee.link:8989/api/addNewsletter", {
              method: "POST",
              body: form_data,
            })
              .then((response) => {
                if (response.status === 200){
                  toast.success("Iscritto!", {
                    containerId: "Alert",
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000, 
                  });   
                } else {
                  toast.error("Errore, riprova o contatta info@3bee.it", {
                    containerId: "Alert",
                    position: toast.POSITION.TOP_CENTER, 
                  });   
                }
              })
              .catch(function(){
                toast.error("Errore, contattaci a info@3bee.it", {
                  containerId: "Alert",
                  position: toast.POSITION.BOTTOM_CENTER, 
                });   
              });
          },
          );
        event.target.reset();
        return false;
      }}
    >
      <StyledFormBlock>
        <StyledFormInput
          id="newsletter-email"
          type="email"
          name="email"
          onInput={(e) => {
            setFormData({
              ...formData,
              email: e.currentTarget.value, 
            });
          }}
        />
        <StyledFormLabel
          htmlFor="newsletter-email"
          isHidden={formData.email && formData.email.length > 0}
        >
          {labels.MAIL_ADDRESS}
        </StyledFormLabel>
      </StyledFormBlock>
      <StyledCountdownNewsletterButton>
        <StyledButton type="submit">
          {labels.SUBSCRIBE}
        </StyledButton>
      </StyledCountdownNewsletterButton>
    </StyledForm>
  );
};

export default CountdownNewsletter;
