import styled from "styled-components";
import {
  Rem, Colors, BP, ShadowBlack, 
} from "../../commons/Theme";
import { StyledBusinessClientReviewsItem } from "../BusinessClientReviews/style";
import { StyledCountdownWrapper } from "../Countdown/style";
import { StyledForm } from "../../commons/Form";

export const StyledCountdownNewsletter = styled.div`
  margin-top: 31vw;
`;

export const StyledCountdownNewsletterCards = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (${BP.ipad}) {
    flex-direction: row;
  }
`;

export const StyledCountdownNewsletterCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${ShadowBlack};
  border: ${Rem(4)} solid ${Colors.yellow};
  box-sizing: border-box;
  border-radius: ${Rem(6)};
  padding: ${Rem(15)};
  background-color: ${Colors.white};

  flex-basis: 0;
  flex-grow: 1;

  @media (${BP.ipad}) {
    padding: ${Rem(40)};
  }

  ${StyledBusinessClientReviewsItem} {
    min-width: 0;
    margin: 0;
  }

  ${StyledCountdownWrapper} {
    padding: 0;
  }

  ${StyledForm} {
    width: 100%;
    max-width: ${Rem(320)};
  }
`;

export const StyledCountdownNewsletterTitle = styled.h2`
  font-weight: 900;
  font-size: ${Rem(30)};
  text-align: center;
  color: ${Colors.yellow};
  padding-top: ${Rem(30)};
  padding-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
  }
`;

export const StyledCountdownNewsletterButton = styled.div`
  text-align: center;
  margin-top: ${Rem(40)};
`;
