import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledCountdownWrapper = styled.div`
  padding-top: ${Rem(32)};
  padding-bottom: ${Rem(32)};
  width:100%;
  align-items: center;
  text-align: center;
`;

export const StyledCountDigitsWrapper = styled.span`
  margin: 1%;
  display: inline-block;
  width: 23%;
  max-width: ${Rem(150)};
`;

export const StyledCountDigits = styled.span`
  background-color: ${Colors.yellow};
  color: ${Colors.white};
  font-size:${Rem(38)};
  border-radius:${Rem(5)};
  display: inline-block;
  padding-top:${Rem(10)};
  padding-bottom:${Rem(10)};
  width: 100%;
  @media(${BP.ipad}) {
    font-size:${Rem(82)};
  }
`;

export const StyledCountText = styled.div`
  margin-top: ${Rem(20)};
  font-size: ${Rem(16)};
  @media(${BP.ipad}) {
    font-size:${Rem(32)};
  }
`;
